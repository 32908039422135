img {
    display: block;
    max-width: 100%;
}

.title {
    margin: 130px auto;
    width: 100%;
    max-width: 633px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    color: #ed1c8f;
    font-size: 32px;
    font-weight: 800;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    border-radius: 40px;
    border: 1px solid #ed1c8f;
}

.footer {
    position: relative;
}

.footer::after,
.footer::before {
    content: "";
    display: block;
    width: 20px;
    height: 100%;
    background: url("/public/background-vertical-rainbow.png") top no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
}

.footer::before {
    right: unset;
    left: 0;
}

.content {
    padding: 0 30px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.backgroundFooter {
    max-width: 26px;
}

.backgroundFooter img {
    object-fit: cover;
    height: 100%;
}

.content {
    flex: 1;
    padding: 90px 52px;
    font-weight: 700;
    color: #333;
}

.d-flex {
    display: flex;
    justify-content: center;
}

.content-logo {
    display: grid;
    align-items: center;
    justify-content: center;
}

.content-logo:last-child {
    grid-column: 1/6;
    display: flex;
    justify-content: center;
}

.patrocinadores > div:not(:first-child) {
    margin-left: 42px;
}

.apoio {
    flex-wrap: wrap;
}

.patrocinadores,
.parceiros-institucionais {
    margin-top: 38px;
    max-width: 1024px;
    gap: 20px;
}

.parceiros-institucionais {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.patrocinadores img {
    object-fit: cover;
}

.content-wrapper {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parceiros-midias {
    display: flex;
    gap: 25px;
}

.content-wrapper[data-parceria-midia] > strong {
    margin-bottom: 38px;
}

.parceiros-institucionais .content-logo:last-child {
    margin-top: 30px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .parceiros-institucionais {
        grid-template-columns: repeat(2, 1fr);
    }

    .content-logo:last-child {
        grid-column: initial;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
}

@media screen and (max-width: 767px) {
    .patrocinadores.d-flex {
        flex-direction: column;
        align-items: center;
    }

    .content-logo:last-child {
        margin-top: 0;
        grid-column: initial;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }

    .parceiros-institucionais {
        grid-template-columns: 1fr !important;
    }

    .content-wrapper > strong {
        text-align: center;
    }

    .patrocinadores > div.content-logo {
        margin-left: 0;
        height: 120px;
    }

    .patrocinadores > div.content-logo[data-element-anormale="true"] {
        position: static !important;
        top: 0;
    }

    .content-wrapper[data-parceiro-inst] > strong {
        display: inline-block;
        margin-bottom: 20px;
    }

    .parceiros-midias {
        flex-direction: column;
        gap: 40px;

        > :last-child {
            margin-top: 40px;
        }
    }

    [data-margin-bottom="40"] {
        margin-bottom: 40px;
    }

    [data-margin-bottom="20"] {
        margin-bottom: 20px;
    }

    .title {
        margin-bottom: 30px;
        margin-top: 0;
        font-size: 18px;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    /* Safari 10.1+ */
    @media (max-width: 767px) {
        .footer {
            z-index: 2;
        }
    }
}
