.section {
    background-color: #000;
}

.container {
    padding: 50px;
    max-width: 880px;
    margin: 0 auto;
    padding: 50px 0;
}

.objectiveContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 138px;
}

.descriptionContainer {
    font-size: 18px;
    padding: 18px 0;
    color: #fff;
}

.imageContainer {
    margin-right: 53px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.spb {
    display: inline-block;
    font-family: "Santa Monica", Arial, Helvetica, sans-serif;
    font-size: 28px;
    color: #db0d6f;
    transform: rotate(-15deg);
    margin-bottom: 60px;
}

.objectiveTitle {
    font-size: 25px;
    max-width: 320px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    margin-bottom: 40px;
    span {
        color: #db0d6f;
    }
}

.objectiveDescription {
    max-width: 320px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 16px;
    font-weight: lighter;
}

.videoContainer {
    display: flex;
    justify-content: center;
    height: 495px;
}

.videoContainer iframe {
    border: none;
    height: 100%;
    width: 100%;
}

@media only screen {
    @media screen and (max-width: 1023px) {
        .container {
            max-width: 90%;
        }

        .spbContainer {
            display: flex;
            justify-content: center;

            .spb {
                margin-bottom: 40px;
            }
        }

        .imageContainer {
            justify-content: center;
            margin-right: 0;
        }

        .objectiveContainer {
            flex-direction: column;
        }

        .objectiveTitle {
            max-width: 100%;
            text-align: center;
        }

        .objectiveDescription {
            max-width: 100%;
        }
    }

    @media (max-width: 500px) {
        .imageContainer img {
            height: 280px;
        }
    }
}
