.content {
    background: url("/public/themes-background.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    position: relative;
    z-index: 2;
}

.content::before {
    content: "";
    display: block;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.73);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.content strong.subtitle {
    font-family: "Monteserrat", Arial, Helvetica, sans-serif;
    font-size: 3.33375rem;
    font-weight: 800;
    text-align: center;
    margin: 70px 0;
}

.content strong.subtitle span {
    position: relative;
    display: inline-block;
    z-index: 1;
}

.content strong.subtitle span::after {
    content: attr(data-tag);
    color: #ed1c8f;
    display: inline-block;
    font-size: 4.631875rem;
    position: absolute;
    font-weight: 400;
    top: 80%;
    left: 50%;
    font-family: "Santa Monica";
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0.7;
}

.content strong.subtitle:nth-of-type(2) span::after {
    top: 120%;
}

.list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.list ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list ul li p {
    font-family: "Gilroy", Arial, Helvetica, sans-serif;
    font-size: 1.2775rem;
    font-weight: 800;
    margin-top: 25px;
    max-width: 120px;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .content strong.subtitle {
        font-size: 2rem;
    }

    .content strong.subtitle span::after {
        font-size: 3rem;
    }
}
