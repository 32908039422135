.section-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.content {
    width: 100%;
    background: url("/public/themes-background.png") center no-repeat #000;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content::before {
    content: "";
    display: block;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.73);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container {
    margin: 0 150px;
    margin-bottom: 160px;
    padding: 0 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(124.32deg, rgba(253, 253, 253, 0.95) 0.01%, rgba(253, 253, 253, 0.42) 99.26%);

    box-shadow: 0px 16.3857px 30.0405px -7.51012px rgba(181, 183, 192, 0.3);
    backdrop-filter: blur(1.86686px);

    border-radius: 8.19286px;
}

.title {
    color: #fff;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 2.9225rem;
    font-weight: 800;
    display: block;
    width: 100%;
    text-align: center;
    margin: 115px 0;
}

.content p.subtitle span::after {
    content: attr(data-tag);
    color: #ed1c8f;
    display: inline-block;
    font-size: 1.74375rem;
    position: absolute;
    top: 70%;
    left: 50%;
    font-family: "Santa Monica";
    transform: translate(-50%, 0);
    z-index: -1;
    opacity: 0.7;
}

.subtitle {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 19.13px;
    font-weight: 400;
    color: #333;
    padding-left: 20px;
    margin: 50px 0;
}

.form {
    font-family: "Gotham", Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.form label {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 0.853125rem;
    display: block;
    margin-bottom: 10px;
    color: #333;
}

.form input {
    border: none;
    outline: none;
    color: #333;
    background-color: transparent;
    border-bottom: 1px solid #ed1c8f;
    display: block;
    width: 100%;
    padding: 5px;
}

.input-wrapper:not(:last-child) {
    margin-bottom: 40px;
}

.select-wrapper {
    max-width: 50%;
    margin-bottom: 40px;
}

.textarea textarea {
    resize: none;
    width: 100%;
    display: block;
    border: 0.960101px solid #ed1c8f;
    border-radius: 10.6678px;
    outline: none;
    padding: 10px;
    font-family: "Gotham", Arial, Helvetica, sans-serif;
    font-weight: 400;

    max-height: 280px;
}

.submit {
    display: flex;
    justify-content: center;
}

.submit button {
    outline: none;
    border: none;
    background-color: #ed1c8f;
    color: #fff;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 1.33375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 60px;
    border-radius: 10.6678px;
    position: relative;
    font-weight: 800;
    cursor: pointer;
    top: -15px;
    margin-bottom: 70px;
}

.submit button:disabled {
    opacity: 0.8;
}

.topicsContainer {
    max-width: 990px;
    min-height: 540px;
    margin: 140px auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 22px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.topic {
    background-color: #fff;
    border-radius: 15px;
    width: 180px;
    height: 105px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 16px 35px 16px;
    position: relative;
}

.topicTitle {
    text-align: center;
    font-size: 20px;
    color: #ed1c8f;
    font-weight: 600;
}

.topicButton {
    width: 31px;
    height: 31px;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: -15.5px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dcdcdc;
    border-radius: 50%;
    transform: translateX(-50%);
}

.topicObjectiveArea {
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
    border-radius: 15px;
}

.topicObjective {
    min-width: 180px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topicObjectiveTitle {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    max-width: 65%;
    color: #000;
    text-decoration: underline;
}

.topicsUL {
    margin-top: 30px;
    position: relative;
    overflow-y: hidden;
}

.topicsList {
    transform: translateY(-1000px);
    animation: topicsAnimation 0.15s forwards;
    margin-bottom: 30px;
    overflow-x: hidden;
    width: 180px;
    border-radius: 15px;
}

.topicObjectiveDescription {
    font-size: 9px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 14px;
    color: #000;
}

.topicsList:nth-of-type(1) {
    animation-delay: 0.05s;
}

.topicsList:nth-of-type(2) {
    animation-delay: 0.1s;
}
.topicsList:nth-of-type(3) {
    animation-delay: 0.15s;
}

.formTitle {
    width: 100%;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ed1c8f;
    font-size: 32px;
    font-weight: 800;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    border-radius: 40px;
    border: 1px solid #ed1c8f;
    margin-top: 57px;
}

@keyframes topicsAnimation {
    to {
        transform: translateY(0);
    }
}

@media screen and (max-width: 767px) {
    .container {
        margin: 0 20px;
        margin-bottom: 60px;
        padding: 0 20px;
    }

    .select-wrapper {
        max-width: 100%;
    }

    .title {
        font-size: 2rem;
    }

    .submit button {
        margin-bottom: 20px;
    }

    .img-area {
        width: 300px;
        top: 200px;
    }
}

@media screen and (max-width: 1023px) {
    .topicsContainer {
        margin-top: 30px;
        grid-template-columns: auto;
        justify-items: center;
    }

    .formTitle {
        font-size: 18px;
    }
}
