@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Medium.otf") format("woff");
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Santa Monica";
  src: url("./assets/fonts/SantaMonica-Tag.otf") format("woff");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
}

body {
  margin: 0;
  font-size: 16px;
  font-family: "Gotham", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
