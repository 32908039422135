.container {
    padding: 60px 40px;
    max-width: 933px;
    margin: 0 auto;
}

.title {
    text-transform: uppercase;
    color: #db0d6f;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 53.34px;
    line-height: 62px;
    margin-bottom: 50px;
    font-family: 800;
    text-align: center;
}

.section {
    position: relative;
}

.section::after,
.section::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 14px;
    background: url("/public/horizontal-bar.png") top no-repeat;
    background-size: cover;
    position: absolute;
}

.section::before {
    top: 0;
    left: 0;
}

.section::after {
    bottom: 0;
    left: 0;
}

.content {
    display: flex;
}

.description {
    flex: 1;
}

.subtitle {
    color: #db0d6f;
    font-size: 35px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    margin-bottom: 50px;
}

.counting {
    font-size: 14px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 200;
    margin-bottom: 15px;
}

.counting strong {
    font-size: 22px;
}

.counting > div {
    width: 260px;
}

.counting > div span {
    position: relative;
    left: 25%;
}

.imageContainer {
    min-width: 659px;
}

.imageContainer img {
    width: 100%;
}

@media screen {
    @media (max-width: 1023px) {
        .imageContainer {
            min-width: auto;
        }

        .container {
            padding: 90px 30px;
        }
        .title {
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 80px;
        }

        .subtitle {
            text-align: center;
            font-size: 25px;
        }

        .content {
            flex-direction: column;
        }

        .countingArea {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 30px;
            margin-bottom: 60px;
        }

        .counting {
            width: 260px;
        }
    }
}
