@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap");

.content {
    width: 100%;
    background: url("../../../public/header-background.png") no-repeat;
    background-size: cover;
    background-position: bottom;
    overflow-x: hidden;
}

.container {
    padding: 165px 120px;
}

.content-area {
    display: flex;
    justify-content: center;
}

.nav {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 47px;
    left: 0;
    margin: 0 auto;
    width: 100%;
    background-color: transparent;
    z-index: 999;
    height: 33px;
}

.nav ul {
    display: flex;
    align-items: center;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 9px;
    font-weight: 800;
    gap: 60px;
    color: #fff;
    text-transform: uppercase;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 15px 30px;
}

.nav ul li {
    border-bottom: 2px solid transparent;
}

.nav ul li:hover {
    border-bottom: 2px solid #fff;
}

.nav ul li a {
    color: currentColor;
}

.description {
    font-size: 1.5625rem;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    color: #fff;
    padding-left: 45px;
    margin-left: 35px;
    border-left: 1px solid #fff;
    text-transform: uppercase;
    z-index: 2;
    text-align: start;
}

span[data-tag] {
    position: relative;
}

.menu-mobile-area {
    display: none;
}

.linksArea {
    margin-top: 85px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.linksArea .linkContent {
    margin-bottom: 30px;
    border: 1px solid #fff;
    border-radius: 40px;
}

.linksArea .linkContent a {
    color: #fff;
    font-size: 21px;
    line-height: 31px;
    font-weight: 800;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding: 10px 20px;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .content-area {
        flex-direction: column;
        align-items: center;
    }

    body {
        padding-top: 0;
    }

    .container {
        padding-left: 52px;
        padding-right: 52px;
    }

    .description {
        margin-left: 0;
        padding-left: 0;
        margin-top: 20px;
        border-left: 0;
        padding-top: 45px;
        border-top: 1px solid #fff;
        text-align: left;
    }

    .description span[data-tag]::before {
        font-size: 3.5rem;
        left: -190px;
        top: 15px;
    }

    .linksArea .linkContent a {
        font-size: 16px;
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (min-width: 411px) and (max-width: 767px) {
    .description span[data-tag]::before {
        top: -11px;
        left: -8px;
    }
}

@media screen and (max-width: 1023px) {
    .nav {
        display: none;
    }

    .mobile-closer {
        margin-bottom: 50px;
        font-weight: bold;
        cursor: pointer;
    }

    .mobile-opener {
        position: absolute;
        top: 10px;
        right: 15px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        cursor: pointer;
    }

    .mobile-opener span {
        width: 40px;
        height: 5px;
        background-color: #fff;
        border-radius: 3px;
    }

    .menu-mobile-area {
        width: 100%;
        color: #fff;
        color: #fff;
        z-index: 999;
        width: 60vw;
        height: 101vh;
        position: fixed;
        top: 0;
        right: 0;
        background-color: rgb(48, 44, 44);
        padding: 30px 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .menu-mobile-area ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    .menu-mobile-area ul li a {
        color: currentColor;
    }
}
